<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AppIcon from "@/features/general/Icons";
import staggeredList from "@/mixins/staggeredList";
import AppAlert from "@/features/bootstrap/Alert";
import AppAddressPreview from "./preview";

export default {
  name: "AppAddressesList",
  mixins: [staggeredList],
  components: {
    AppAddressPreview,
    AppAlert,
    AppIcon,
  },

  data() {
    return {
      alert_dismissCountDown: 10,
      loading: true,
    };
  },

  mounted() {
    this.fetch();
  },

  beforeUnmount() {
    this.clearMessages();
  },

  methods: {
    ...mapActions("Address", ["setAddresses", "clearMessages"]),
    /**
     * Busca os endereços do usuário
     */
    fetch() {
      const addressesPath = "/addresses";
      this.loading = true;
      this.$http
        .get(addressesPath)
        .then((response) => {
          const { data } = response.data;
          this.setAddresses({ addresses: data });
        })
        .catch((error) => error)
        .then(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    ...mapState("Address", {
      messages: (state) => state.address.messages,
    }),

    ...mapGetters(["langs"]),

    ...mapGetters(["storeSettings"]),

    ...mapGetters("Address", ["billingAddress", "deliveryAddresses"]),

    /**
     * @return {number} Indice de exibição do ultimo endereço disponível
     */
    lastIndex() {
      return this.deliveryAddresses.length;
    },

    /**
     * Determina se o usuário pode criar um novo endereço.
     *
     * @return {boolean}
     * */
    allowNewAddress() {
      return this.lastIndex === 0 || +this.storeSettings.multi_address === 1;
    },
  },
};
</script>
<template>
  <section class="app__address-list">
    <app-alert :messages="messages" />
    <header class="app__address-list__header">
      <h3
        class="app__address-list__title--3"
        :class="{ app__loading: loading }"
      >
        {{ this.langs.addresses["my-addresses"] }}
      </h3>
    </header>
    <router-link
      :to="{ name: 'addresses.new' }"
      custom
      v-slot="{ navigate }"
      data-test="app__address-list__add-new"
      :class="{ app__loading: loading }"
    >
      <div
        class="col-sm-12 col-md-6 app__address-list__add-new"
        @click="navigate"
        @keypress.enter="navigate"
        v-if="allowNewAddress"
      >
        <div
          class="app__address-list__add-new__label"
          :class="{ app__loading: loading }"
          data-cy="add-new-address"
        >
          {{ this.langs.addresses["add-address"] }}
        </div>
        <figure class="app__address-list__add-icon">
          <app-icon
            name="plus-circle"
            prefix="fa"
            class="app__address-list__add-icon"
          >
          </app-icon>
        </figure>
      </div>
    </router-link>

    <transition-group
      name="app__animated__staggered-list"
      tag="div"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:leave="leave"
      class="app__addresses__address"
      v-if="deliveryAddresses || loading"
    >
      <app-address-preview
        v-for="(address, index) in deliveryAddresses"
        class="app__address-list__item app__animated__staggered-list__item"
        v-bind:data-index="index"
        v-bind:key="address.id"
        :id="address.id"
        :index="index"
        :active="address.active"
        :city="address.city"
        :complement="address.complement"
        :country="address.country"
        :neighborhood="address.neighborhood"
        :number="address.number"
        :recipient="address.recipient"
        :state="address.state"
        :street="address.street"
        :type="address.type"
        :zipCode="address.zip_code"
        :hash="address.hash"
        :parentLoading="loading"
      >
      </app-address-preview>
    </transition-group>

    <section id="billing-address" class="animated app__address-billing">
      <header class="app__address-billing__header" v-if="billingAddress">
        <h3
          class="app__address-billing__title"
          :class="{ app__loading: loading }"
        >
          {{ this.langs.addresses["billing-address"] }}
        </h3>
      </header>
      <transition
        name="app__staggered-list-transition"
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
        v-on:leave="leave"
      >
        <app-address-preview
          v-if="billingAddress"
          class="app__address-list__item"
          :data-index="lastIndex"
          :id="billingAddress.id"
          :active="billingAddress.active"
          :city="billingAddress.city"
          :country="billingAddress.country"
          :complement="billingAddress.complement"
          :neighborhood="billingAddress.neighborhood"
          :number="billingAddress.number"
          :recipient="billingAddress.recipient"
          :state="billingAddress.state"
          :street="billingAddress.street"
          :zipCode="billingAddress.zip_code"
          :type="billingAddress.type"
          :hash="billingAddress.hash"
          :parentLoading="loading"
        >
        </app-address-preview>
      </transition>
    </section>
  </section>
</template>
