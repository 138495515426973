export default {
  data() {
    return {
      delay: 0,
    };
  },
  methods: {
    /**
     * Antes da transição iniciar
     * esconde todos os elementos
     * @param {element}
     */
    beforeEnter(element) {
      const groupElement = element;
      groupElement.style.opacity = 0;
    },

    /**
     * Aplica um delay nas animações
     * @param {element}
     */
    enter(element, currentPage) {
      const groupElement = element;
      this.delay = groupElement.dataset.index * 100;

      if (currentPage > 1) {
        this.delay = (groupElement.dataset.index % 10) * 100;
      }

      setTimeout(() => {
        groupElement.classList.add("app__animated--zoom-in");
        groupElement.style.opacity = 1;
      }, this.delay);
    },

    /**
     * Aplica um delay nas animações
     * @param {element}
     */
    leave(element) {
      const groupElement = element;
      groupElement.classList.add("app__animated", "app__animated--zoom-out");
    },
  },
};
