export default class PngQrCode {
  /**
   * Tipo do QrCode.
   * @var {string} type
   */
  type = "image/png";

  /**
   * url com o tipo do qrcode.
   * @var {string}
   */
  path;

  /**
   * Método construtor da classe.
   * @param {String} path
   */
  constructor(path) {
    this.path = path;
  }

  /**
   * Retorna o type do Qrcode
   * @return {string}
   */
  getType() {
    return this.type;
  }

  /**
   * Retorna o conteúdo do Qrcode a ser renderizado no
   * data do object.
   *
   * @return {string}
   */
  getData() {
    return this.path;
  }
}
