<script>
import { mapState, mapGetters } from "vuex";
import StepType from "../../../models/steps/step.type";
import AppQrCode from "../../../../Qrcode/index.vue";

export default {
  name: "AppTimelineActionPixPayment",

  components: {
    AppQrCode,
  },

  data() {
    return {
      pixCodeCopied: false,
    };
  },

  props: {
    step: {
      type: StepType,
    },
    cssClass: {
      type: String,
      default: "",
    },
    order: {
      urls: {
        code: {
          type: String,
          default: "",
        },
        transaction: {
          type: String,
          default: "",
        },
        payment: {
          type: String,
          default: "",
        },
      },
    },
  },

  beforeDestroy() {
    this.hideModal();
  },

  methods: {
    /**
     * Seleciona o código do pix para o usúario
     * @return {undefined}
     */
    selectCode() {
      const selection = window.getSelection();
      const range = document.createRange();

      range.selectNodeContents(this.$refs.pixCode);
      selection.removeAllRanges();
      selection.addRange(range);
    },

    /**
     * Copia o conteúdo do elemento para o clipboard
     * @return {undefined}
     */
    copyToClipboard() {
      const clearText = this.$refs.pixCode.innerHTML.replace(/\r?\n|\r/g, "");
      navigator.clipboard.writeText(clearText);
    },

    /**
     * Copia o código da transação
     * @return {undefined}
     */
    copyCode() {
      this.selectCode();
      this.copyToClipboard();
      this.pixCodeCopied = true;
    },
  },

  computed: {
    ...mapState("Orders", {
      model: (state) => state.order.model,
    }),

    ...mapGetters(["langs"]),

    /**
     * Retorna a url com para renderizar o svg
     * @return {string}
     */
    svgPath() {
      return this.order.urls.transaction;
    },

    /**
     * Retorna a url com para renderizar o svg
     * @return {string}
     */
    pixCode() {
      return this.order.urls.code;
    },

    /**
     * Retorna o texto do botào copiar
     * @return {string}
     */
    buttonLabel() {
      return this.pixCodeCopied
        ? this.langs.order["pix-code-copied"]
        : this.langs.order["copy-pix-code"];
    },

    /**
     * Retorna o texto "Pager Pix" das langs
     * @return {string}
     */
    payPixText() {
      return this.langs.order.timeline?.["payment-step"].action.pix.open;
    },

    /**
     * Valida se há um QR code para o pagamento do pix
     * @return {bool}
     */
    hasPixCode() {
      return this.order.urls.code !== "" && this.order.urls.transaction !== "";
    },

    /**
     * Link de pagamento externo do pix
     * @return {string}
     */
    paymentPixUrl() {
      return this.order.urls.payment;
    },
  },
};
</script>

<template>
  <div class="app__timeline__action-shipment">
    <button
      v-if="this.hasPixCode"
      type="button"
      v-html="payPixText"
      :class="cssClass"
      data-bs-toggle="modal"
      data-bs-target="#paymentPixModal"
      :data-test="`timeline-step-action-${step?.getStepName()?.toLowerCase()}`"
    />
    <a
      v-else
      v-html="payPixText"
      :href="this.paymentPixUrl"
      :class="cssClass"
    />

    <div
      class="modal fade"
      id="paymentPixModal"
      tabindex="-1"
      aria-labelledby="paymentPixModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              <app-qr-code
                class="app__timeline__pix-qrcode"
                :path="this.svgPath"
              />
            </p>

            <p></p>

            <p class="app__timeline__action-shipment__pix-code">
              <strong class="app__timeline__action-shipment__pix-code-label">
                {{ this.langs.order["pix-code-label"] }}
              </strong>
              <span ref="pixCode">
                {{ this.pixCode }}
              </span>
            </p>
          </div>
          <div class="modal-footer">
            <button
              ref="copyButton"
              class="app__button app__timeline__action-shipment__pix-button"
              :class="{
                'app__button--primary': pixCodeCopied,
                'app__button--primary-outline': !pixCodeCopied,
              }"
              @click="copyCode"
            >
              {{ buttonLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
