<script>
export default {
  name: "AppOrders",
};
</script>
<template>
  <section class="app__orders">
    <router-view v-slot="{ Component }">
      <transition
        name="custom-classes-transition"
        enter-active-class="app__animated app__animated--fade-in"
        leave-active-class="app__animated app__animated--fade-out"
        mode="out-in"
      >
        <component :is="Component" :key="$route.path" />
      </transition>
    </router-view>
  </section>
</template>
