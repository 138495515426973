export default {
  data() {
    return {
      messages: [],
    };
  },
  methods: {
    /**
     * Rola a tela após um update da view
     * https://developer.mozilla.org/en-US/docs/Web/API/ScrollToOptions
     * @param {object} scrollToOptions
     * @return {Promise}
     */
    scrollTo(
      scrollToOptions = {
        top: 400,
        left: 0,
        behavior: "smooth",
      }
    ) {
      const { top, left, behavior = "smooth" } = scrollToOptions;
      return this.$nextTick(() => {
        window.scroll({
          top,
          left,
          behavior,
        });
      });
    },

    /**
     * Define uma mensagem
     * success | warning | error
     * @param {object} message
     */
    setMessage(
      message = {
        text: "message",
        variant: "error",
      }
    ) {
      this.messages.push(message);
    },
  },
};
