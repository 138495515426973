<script>
import AppSvg from "@/features/general/Svg";

export default {
  name: "AppAlert",
  props: {
    messages: {
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },

  components: {
    AppSvg,
  },
};
</script>

<template>
  <div class="app__alert" id="alert-container">
    <div
      v-for="(message, index) in this.messages"
      :key="index"
      :class="'alert-' + message?.variant"
      class="app__alert__container alert alert-dismissible fade show"
      role="alert"
    >
      <p class="app__alert__title" v-if="message?.title">
        <app-svg
          :name="message?.title?.icon"
          :class="{ app__loading: loading }"
        />
        <b v-html="message?.title?.text" :class="{ app__loading: loading }" />
      </p>
      <span v-html="message?.text" :class="{ app__loading: loading }" />
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      />
    </div>
  </div>
</template>
