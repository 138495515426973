<script>
import { mapGetters, mapState } from "vuex";
import deburr from "lodash/deburr";

export default {
  name: "AppOrderPaymentLink",

  props: {
    order: {
      type: Object,
      default() {
        return {
          access_code: null,
          paymentMethod: {
            name: null,
          },
          status: {
            store: null,
          },
        };
      },
    },
  },

  methods: {
    /**
     * Retorna o valor do jwtToken do localStorage
     * @return {string}
     */
    jwtToken() {
      return localStorage.getItem("jwtToken");
    },
  },

  computed: {
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),
    ...mapGetters(["langs"]),

    /**
     * Valida se foi feito o pagamento
     * @return {boolean}
     */
    hasPayment() {
      return !!this.order.payment;
    },

    /**
     * Retorna o método de pagamento do pedido.
     * @return string
     * */
    paymentMethod() {
      return deburr((this.order.paymentMethod.name || "").toLowerCase());
    },

    /**
     * Verifica se o pedido está em andamento
     * @return {boolean}
     */
    isOrderOpen() {
      return this.order.status.type === "open";
    },

    /**
     * Retorna o status do pedido.
     * @return {null|string}
     * */
    paymentStatus() {
      return this.order.status.store;
    },

    /**
     * Retorna o código de acesso do pedido.
     * @return {null|string}
     * */
    paymentAccessCode() {
      return this.order.access_code;
    },

    /**
     * URL de transação do pedido
     * @return {null|string}
     */
    urlTransaction() {
      return this.order.urls.transaction;
    },

    /**
     * Retorna a chave 'payment_method_message.confirmation', caso exista
     * @return {object}
     * */
    paymentMethodMessageConfirmation() {
      return this.order.payment_method_message?.confirmation;
    },

    /**
     * Valida se o pedido permite confirmação de pagamento
     * @return {bool}
     * */
    paymentConfirmation() {
      return this.paymentMethodMessageConfirmation === "1";
    },

    /**
     * Valida se existe um boleto pendente a pagar
     * @return {boolean}
     */
    isPendingBillet() {
      return (
        this.paymentMethod.indexOf("boleto") !== -1 &&
        !!this.urlTransaction &&
        this.isOrderOpen &&
        !this.hasPayment
      );
    },

    /**
     * Link de confirmaçlconfirmção de pagamento
     * @return {string}
     */
    paymentConfirmationLink() {
      let route = "";

      if (!this.hasPayment && this.paymentConfirmation) {
        let queries = `loja=${this.store_info.id}&pedido=${this.paymentAccessCode}&status=${this.paymentStatus}`;
        route = `${this.store_info.uri.https}/loja/central_confirmar_pagamento.php?${queries}`;
      }

      return route;
    },

    /**
     * Retorna o texto referente a ação do pagamento
     * @return {string}
     */
    paymentActionLabel() {
      const isBillet = this.paymentMethod.indexOf("boleto") !== -1;

      return isBillet
        ? this.langs.order.timeline?.["payment-step"].action.bank_billet.open
        : this.langs.order.timeline?.["payment-step"].action.bank_report_payment
            .open;
    },

    /**
     * Cria um link de pagamento
     * @return {string}
     */
    route() {
      let route = "";

      // Verifica se o pedido tem access_code e da loja tem URL
      if (this.paymentAccessCode && this.store_info.uri.https) {
        route = this.paymentConfirmationLink;
      }

      // Utiliza o link de boleto que retorna da API
      if (this.isPendingBillet) {
        route = this.urlTransaction;
      }

      return route;
    },
  },
};
</script>

<template>
  <div>
    <a
      v-if="!!route"
      :href="route"
      target="_blank"
      class="app__button app__button--primary-outline"
    >
      {{ paymentActionLabel }}
    </a>
  </div>
</template>
