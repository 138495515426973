<script>
import { mapGetters } from "vuex";

export default {
  name: "AppOrderNotFound",

  props: {
    loading: Boolean,

    list: {
      type: Object,
      default() {
        /* istanbul ignore next */
        return {
          store: {
            id: "",
            uri: {
              https: "",
            },
          },
        };
      },
    },
  },

  computed: {
    ...mapGetters(["langs"]),
  },
};
</script>

<template>
  <section class="app__order-not-found">
    <div
      class="col-sm-6 col-md-4 offset-sm-3 offset-md-4"
      :class="{ app__loading: loading }"
    >
      <div class="app__order-not-found__emotion">:'(</div>
    </div>
    <h2
      class="app__order-not-found__title app__order-not-found__title--2"
      :class="{ app__loading: loading }"
    >
      {{ this.langs.order["no-order"] }}
    </h2>
    <h4 class="app__order-not-found__text" :class="{ app__loading: loading }">
      {{ this.langs.order["search-store"] }}
      <p>
        <a
          :href="this.list.store.uri.https"
          class="app__link app__link--featured"
        >
          {{ this.langs.order["find-products"] }}
        </a>
      </p>
    </h4>
  </section>
</template>
