<script>
import QrCodeModel from "./models/QrCode";

export default {
  name: "AppQrCode",

  props: {
    /**
     * url com o tipo do qrcode.
     * @var {string} path
     */
    path: {
      type: String,
    },
  },

  data() {
    return {
      qrCode: new QrCodeModel(this.path),
    };
  },

  inheritAttrs: false,

  methods: {
    /**
     * Retorna o conteúdo do Qrcode a ser renderizado em tela.
     *
     * @return {string}
     */
    getData() {
      return this.qrCode?.getData();
    },

    /**
     * Retorna o type do Qrcode
     *
     * @return {string}
     */
    getType() {
      return this.qrCode?.getType();
    },
  },
};
</script>

<template>
  <object
    data-test="qrcode"
    v-bind="$attrs"
    :type="getType()"
    alt="Pix QRcode"
    :data="getData()"
  />
</template>
