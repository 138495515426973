<script>
import AppIcon from "@/features/general/Icons";
import AppSvg from "@/features/general/Svg";
import icon from "@/mixins/icon";
import { SvgIcon } from "@/domains/Orders/components/order-total/icon-resolver/icon";

export default {
  name: "AppOrderListProducts",
  mixins: [icon],
  components: {
    AppIcon,
    AppSvg,
  },

  data() {
    return {
      icons: [new SvgIcon("ellipse")],
      maxProductsShown: 3,
    };
  },

  props: {
    product: {},
    index: {
      default: 0,
      type: Number,
    },
    length: {
      default: 0,
      type: Number,
    },
  },

  computed: {
    /**
     * Retorna o ícone
     * @return {array}
     */
    icon() {
      return this.hasDef(this.icons);
    },
  },
};
</script>

<template>
  <div class="app__order-list__order-products">
    <div v-if="index < this.maxProductsShown">
      <figure
        class="app__order-list__order-products__qty-ellipse"
        v-if="product.quantity > 1"
      >
        <app-svg :name="icon.name" class="app__icon--ellipse"> </app-svg>
        <div
          class="app__order-list__order-products__qty-ellipse__qty-text-block"
        >
          <h3
            class="app__order-list__order-products__qty-ellipse__qty-text-block__quantity-text"
          >
            {{ product.quantity }}
          </h3>
        </div>
      </figure>
      <img
        v-if="product.ProductImage?.[0]?.thumbs?.['30']?.https"
        :src="product.ProductImage[0].thumbs['30'].https"
        class="app__order-list__order-products__image"
      />
      <app-icon
        v-else
        class="app__order-list__order-products__image"
        name="eye-slash"
      />
    </div>
    <figure v-if="index == this.maxProductsShown">
      <div class="app__order-list__order-products__text-block">
        <h3 class="app__order-list__order-products__text-block__index">
          +{{ length - index }}
        </h3>
      </div>
      <img
        class="app__order-list__order-products__show-more"
        :src="product.image"
      />
    </figure>
  </div>
</template>
