<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "AppAddressPreview",

  data() {
    return {
      loading: false,
    };
  },

  props: {
    active: String,
    city: String,
    complement: String,
    id: String,
    neighborhood: String,
    number: String,
    parentLoading: Boolean,
    recipient: String,
    type: String,
    state: String,
    street: String,
    zipCode: String,
    country: String,
    index: Number,
    hash: String,
  },

  methods: {
    ...mapActions("Address", ["deleteAddress", "setMessage", "clearMessages"]),

    /**
     * Remove um endereço da lista de endereços
     * disponíveis.
     *
     * @param {object} address endereço
     */
    removeAddress() {
      const deleteAddressPath = `addresses/${this.hash}`;
      this.loading = true;

      this.$http
        .delete(deleteAddressPath)
        .then(() => {
          this.deleteAddress(this.id);
          this.clearMessages();
          this.setMessage({
            variant: "success",
            text: this.returnMessage("success"),
          });
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => {
          this.clearMessages();
          this.scrollTop();
          this.setMessage({
            variant: "danger",
            text: this.returnMessage("fail"),
          });
          this.loading = false;
          throw error;
        });
    },

    /**
     * Posiciona o scroll da pagina no topo
     * @return void
     */
    scrollTop() {
      window.scroll({
        top: 130,
        left: 0,
        behavior: "smooth",
      });
    },

    /**
     * Retorna a mensagem de retorno para a remoção do endereço
     * @param {string} message indica se a mensagem é um sucesso ou falha
     * @returns {string}
     */
    returnMessage(message) {
      let addressType = this.langs.addresses.billing;

      if (this.isDeliveryAddress) {
        addressType = this.langs.addresses.delivery;
      }

      return `${this.langs.addresses[`${message}-remove-address-one`]}
      ${addressType}
      ${this.langs.addresses[`${message}-remove-address-two`]}`;
    },
  },

  computed: {
    ...mapGetters(["langs"]),

    ...mapState("Customer", {
      customerName: (state) => state.customer.list.name,
    }),

    /**
     * Retorna o destinatário
     * @param {object} address Objeto de endereço
     * @return {string} Destinatário
     */
    computedRecipient() {
      return this.recipient || this.customerName;
    },

    /**
     * Verifica se o endereço é o principal
     * @return {boolean}
     */
    isMainAddress() {
      const ACTIVE_ADDRESS = "1";
      return this.active === ACTIVE_ADDRESS;
    },

    /**
     * Verifica se o endereço é um endereço de entrega
     * @return {boolean}
     */
    isDeliveryAddress() {
      // eslint-disable-next-line
      return this.type == "1";
    },

    /**
     * Verifica se o endereço é um endereço de cobrança
     * @return {boolean}
     */
    isBillingAddress() {
      return !this.isDeliveryAddress;
    },

    /**
     * Verifica se a aplicação está ou não carregando
     * @returns {boolean}
     */
    isLoading() {
      return this.loading || this.parentLoading;
    },

    /**
     * Verifica se o endereço é internacional
     * @return {boolean}
     */
    isInternationalAddress() {
      if (!this.country) {
        return false;
      }

      return this.country !== "BRA";
    },

    /**
     * Verifica a label a ser usada para o CEP caso o endereço seja internacional
     * @returns {string}
     */
    useInternationalPostalCode() {
      if (this.isInternationalAddress) {
        return this.langs.addresses["postal-code"];
      }

      return "CEP";
    },
  },
};
</script>
<template>
  <section :id="`address-${id}`">
    <div class="row">
      <div class="col-7 app__address-list__item--infos">
        <div
          class="app__address-list__item__text--title"
          v-if="isDeliveryAddress"
          :class="{ app__loading: isLoading }"
        >
          <span
            class="app__address-list__item__text__customer-name"
            v-text="computedRecipient"
          >
          </span>
          <span
            class="badge badge-success"
            v-show="isMainAddress && !isLoading"
          >
            {{ this.langs.addresses["main-address"] }}
          </span>
        </div>
        <span
          class="app__address-list__item__text"
          :class="{ app__loading: isLoading }"
        >
          {{ street }}, {{ number }}
        </span>
        <span
          v-if="complement"
          class="app__address-list__item__text"
          :class="{ app__loading: isLoading }"
        >
          {{ complement }}
        </span>
        <span
          class="app__address-list__item__text"
          :class="{ app__loading: isLoading }"
        >
          {{ neighborhood }} - {{ city }}/{{ state }}
        </span>
        <span
          class="app__address-list__item__text"
          :class="{ app__loading: isLoading }"
        >
          {{ this.useInternationalPostalCode }}: {{ zipCode }}
        </span>
      </div>

      <div class="col-5 app__address-list__item__actions gap-2">
        <router-link
          tag="a"
          class="app__button app__button--primary app__button--no-margin"
          :class="{ app__loading: isLoading }"
          :title="id"
          :to="{ name: 'addresses.edit', params: { addressId: hash } }"
        >
          {{ this.langs.addresses["edit-address"] }}
        </router-link>
        <button
          :id="`options-${id}`"
          v-show="!isMainAddress || isBillingAddress"
          @click="removeAddress"
          class="app__button app__button--secondary-outline app__button--no-margin"
          :class="{ app__loading: isLoading }"
        >
          Excluir
        </button>
      </div>
    </div>
  </section>
</template>
