import Base64QrCode from "./Base64QrCode.js";
import PngQrCode from "./PngQrCode.js";
import SvgQrCode from "./SvgQrCode.js";

export default class QrCodeModel {
  /**
   * Estratégia com a instância do QrCode.
   * @var {SvgQrCode|PngQrCode|Base64QrCode}
   */
  strategy;

  /**
   * url com o tipo do qrcode.
   * @var {string}
   */
  path;

  /**
   * Método construtor da classe.
   *
   * @param {String} path
   */
  constructor(path) {
    this.path = path;
    this.strategy = this.qrCodeType();
  }

  /**
   * Retorna a instância do Qrcode de acordo com o seu tipo.
   *
   * @return {SvgQrCode|PngQrCode|Base64QrCode}
   */
  qrCodeType() {
    if (this.isSvg()) {
      return new SvgQrCode(this.path);
    }

    if (this.isBase64(this.path)) {
      return new Base64QrCode(this.path);
    }

    return new PngQrCode(this.path);
  }

  /**
   * Verifica se a url de qrcode é um base64 valido.
   *
   * @param {String} string
   * @return {boolean}
   */
  isBase64(string) {
    try {
      return window.btoa(window.atob(string)) == string;
    } catch (err) {
      return false;
    }
  }

  /**
   * Verifica se a url de qrcode é padrão svg.
   *
   * @return {boolean}
   */
  isSvg() {
    return (
      this.path.includes(".svg") || this.path.includes("intermediador.yapay")
    );
  }

  /**
   * Retorna o type do Qrcode
   * @return {string}
   */
  getType() {
    return this.strategy.getType();
  }

  /**
   * Retorna o conteúdo do Qrcode a ser renderizado no
   * data do object.
   *
   * @return {string}
   */
  getData() {
    return this.strategy.getData();
  }
}
