<script>
import { mapActions, mapGetters, mapState } from "vuex";
import AppOrderPaymentLink from "./payment-info-link";
import AppPaymentPix from "@/features/general/Timeline/steps/action/behaviors/payment-pix.vue";

export default {
  name: "AppOrderListAction",
  components: {
    AppOrderPaymentLink,
    AppPaymentPix,
  },

  props: {
    order: {
      default: () => {},
      type: Object,
    },
  },

  methods: {
    ...mapActions("Orders", ["setOrdersData", "setOrdersLoading"]),
  },

  computed: {
    ...mapGetters(["langs", "isMobile"]),
    ...mapState("Orders", {
      loading: (state) => state.orders.loading,
    }),

    /**
     * Verifica se o pedido está em andamento
     * @return {boolean}
     */
    isOrderOpen() {
      return this.order.status.type === "open";
    },

    /**
     * Verifica se o pedido tem informações do pagamento
     * @return {boolean}
     */
    hasPaymentInfo() {
      return !!this.order?.payment;
    },

    /**
     * Verifica se deve exibir algum botão de pagamento
     * @return {boolean}
     */
    showPaymentButton() {
      return this.isOrderOpen && !this.hasPaymentInfo;
    },

    /**
     * Verifica se a forma de pagamento é PIX
     * @return {string | boolean}
     */
    paymentPixLink() {
      const isPix =
        this.order.paymentMethod.type === "pix" &&
        !this.order.payment &&
        this.isOrderOpen;

      return isPix;
    },

    /**
     * Verifica se o pedido foi finalizado e se há um link  de nota fiscal
     * @return {bool}
     */
    hasInvoiceLink() {
      if (this.order.invoice.link) {
        try {
          return new URL(this.order.invoice.link);
        } catch (error) {
          return false;
        }
      }
      return false;
    },
  },
};
</script>

<template>
  <div class="app__order-list__order-actions">
    <app-payment-pix
      v-if="paymentPixLink"
      :order="order"
      :cssClass="'app__order-list__order-actions__btn-payment app__button app__button app__button--primary-outline'"
    />
    <a
      v-else-if="hasInvoiceLink"
      :href="hasInvoiceLink"
      class="app__order-list__order-actions__btn-payment app__button app__button app__button--primary-outline"
      :class="{ app__loading: loading }"
      target="_blank"
    >
      {{ this.langs.order.timeline?.["payment-step"].action.default.closed }}
    </a>
    <app-order-payment-link v-else-if="isOrderOpen" :order="order" />
    <router-link
      class="app__order-list__order-actions__btn-details app__button app__button--primary"
      :class="{ app__loading: loading }"
      tag="a"
      :title="order.id"
      :to="{ name: 'orders.detail', params: { id: order.hash } }"
    >
      {{ this.langs.order["see-details"] }}
    </router-link>
  </div>
</template>
