<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AppSvg from "@/features/general/Svg";
import { SvgIcon } from "@/domains/Orders/components/order-total/icon-resolver/icon";
import staggeredList from "@/mixins/staggeredList";
import screenHandler from "@/mixins/screenHandler";
import icon from "@/mixins/icon";

import AppTooltip from "@/features/bootstrap/Tooltip";
import AppOrderListAction from "./order-list-action";
import AppOrderListProducts from "./order-list-products";
import AppOrderNotFound from "./not-found";

export default {
  name: "AppOrderList",
  mixins: [staggeredList, screenHandler, icon],
  components: {
    AppTooltip,
    AppSvg,
    AppOrderListAction,
    AppOrderListProducts,
    AppOrderNotFound,
  },

  beforeUnmount() {
    this.clearMessages();
  },

  data() {
    return {
      loadingMore: false,
      loadMorePage: 1,
      menuMobile: [],
      angleRight: [],
      /**
       * Verifica se todos os pedidos foram carregados
       */
      allLoaded: false,
      loadingOldOrders: false,
      icons: [new SvgIcon("angle-right")],
    };
  },

  methods: {
    setMenuMobile(el) {
      if (el) {
        this.menuMobile.push(el);
      }
    },
    setAngleRight(el) {
      if (el) {
        this.angleRight.push(el);
      }
    },
    /**
     * Busca a lista de pedidos disponíveis
     */
    ...mapActions("Orders", [
      "setOrdersData",
      "setOrdersProducts",
      "setPaymentMethodMessage",
      "setOrdersLoading",
      "clearMessages",
    ]),
    fetch() {
      const ordersPath = "/orders?limit=10";
      this.setOrdersLoading({ loading: true });
      this.$http
        .get(ordersPath)
        .then((response) => {
          this.setOrdersData({
            list: response.data.data,
            pagination: response.data.meta.pagination,
          })
            .then(() => {
              this.setOrdersLoading({ loading: false });
              if (this.isLastPage) {
                this.loadingOldOrders = true;
                this.loadMore({ old_orders: 1, page: 1 });
              }
            })
            .then(() => {
              this.list.forEach((order) => {
                const orderPath = `/orders/${order.hash}`;
                this.$http.get(orderPath).then((orderDetailResponse) => {
                  this.setOrdersProducts({
                    order: order.id,
                    products: orderDetailResponse.data.products,
                    isOpen: false,
                    urls: orderDetailResponse.data.urls,
                    paymentMethod: orderDetailResponse.data.payment_method,
                  });
                  this.setPaymentMethodMessage({
                    order: order.id,
                    paymentMethodMessage:
                      orderDetailResponse.data.paymentMethodMessage,
                  });
                });
              });
            });
        })
        .catch((error) => {
          const notFound = error.status === 404;
          if (notFound) {
            this.setOrdersLoading({ loading: false });
          }
        });
    },

    /**
     * Retorna os dados da loja.
     * @return {object}
     */
    storeList() {
      const list = {
        store: this.storeInfo,
      };
      return list;
    },

    /*
     * Carrega mais pedidos.
     * A partir da última pagina dos pedidos recentes
     * serão carregados apenas os pedidos com mais de 90 dias
     * @param {object} params
     */
    /* eslint no-param-reassign: ["error", { "props": false }] */
    loadMore(params = {}) {
      // eslint-disable-next-line
      this.loadingMore = true;
      this.$http
        .get("/orders", {
          params: {
            page: this.pagination.current_page + 1,
            old_orders: this.loadingOldOrders ? 1 : 0,
            limit: 10,
            ...params,
          },
        })
        .then((response) => {
          const { data, meta } = response.data;

          this.allLoaded = this.list.find((order) => order.id === data[0].id);
          if (this.allLoaded) {
            return;
          }

          this.setOrdersData({
            list: this.list.concat(data),
            pagination: meta.pagination,
          }).then(() => {
            if (!this.pagination.links.next && !this.loadingOldOrders) {
              this.loadingOldOrders = true;
              this.loadMore({ old_orders: 1, page: 1 });
            }
            this.scrollTo({
              top: document.documentElement.scrollTop
                ? document.documentElement.scrollTop + 400
                : 0,
            });
          });
        })
        .then(() => {
          this.list.forEach((order) => {
            const orderPath = `/orders/${order.hash}`;
            if (order.products && order.products.length) {
              return;
            }
            this.$http.get(orderPath).then((response) => {
              this.setOrdersProducts({
                order: order.id,
                products: response.data.products,
                isOpen: false,
                urls: response.data.urls,
              });
              this.setPaymentMethodMessage({
                order: order.id,
                paymentMethodMessage: response.data.paymentMethodMessage,
              });
            });
          });
          this.loadingMore = false;
        })
        .catch((error) => {
          this.loadingMore = false;
          throw error;
        });
    },

    /**
     * Faz o toggle do menu do item selecionado no mobile
     * @param {int} index
     * @return {undefined}
     */
    toggleMenuMobile(index, order) {
      this.setOrdersProducts({
        order: order,
        isOpen: !this.list[index].isOpen,
      });
    },

    /**
     * Verifica se os dados do produto já foram recebidos da API
     * @param {object} order Objeto com os dados do pedido
     * @return {bool}
     */
    hasProducts(order) {
      return order.products?.length > 0;
    },

    /**
     * Define estilo do badge
     * @param {string} backgroundColor
     * @param {string} fontColor
     * @return {object}
     */
    styleStatusBadge(backgroundColor, fontColor) {
      return {
        backgroundColor: backgroundColor,
        color: fontColor,
      };
    },

    /**
     * Define o texto do badge
     * @param {string} displayName Nome principal a ser exibido
     * @param {string} store caso não tenham cadastrado um displayName no painel, este será exibido
     * @return {string}
     */
    badgeText(displayName, store) {
      return displayName ? displayName : store;
    },
  },

  mounted() {
    /**
     * Busca os dados na API logo após renderizar o componente
     */
    this.fetch();
  },
  computed: {
    ...mapGetters(["langs", "isMobile"]),

    ...mapState("Orders", {
      loading: (state) => state.orders.loading,
      pagination: (state) => state.orders.pagination,
      list: (state) => state.orders.list,
    }),

    ...mapState({
      storeInfo: (state) => state.store_info.list,
    }),

    /**
     * Verifica se é a ultima pagina
     * @return {boolean}
     */
    isLastPage() {
      return !this.pagination.links.next;
    },

    /**
     * Verifica se existe pedidos na listagem
     * @returns {boolean}
     */
    hasOrders() {
      return this.list[0].id !== 0;
    },

    /**
     * Retorna o ícone
     * @return {array}
     */
    icon() {
      return this.hasDef(this.icons);
    },

    /**
     * Verifica se o tipo do icon é o tipo desejado
     * @return {bool}
     */
    hasIcon() {
      return this.icon.type === "svg";
    },
  },
};
</script>

<template>
  <section class="app__order-list">
    <header class="app__order-list__header">
      <h3 class="app__order-list__title app__order-list__title--3">
        {{ this.langs.order["order-list"] }}
      </h3>
    </header>
    <transition-group
      name="staggered-list"
      tag="div"
      mode="opt-in"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter($event, loadMorePage)"
      v-if="hasOrders || loading"
    >
      <div class="app__order-list" key="app__order-list" ref="root">
        <article
          class="app__order-list__order"
          data-cy="order-list__order"
          v-for="(order, index) in list"
          :key="order.id"
          v-bind:data-index="index"
          v-show="order.id || loading"
          :class="{
            'app__order-list__order--open': order.status.type == 'open',
            'app__order-list__order--closed': order.status.type == 'closed',
            'app__order-list__order--canceled': order.status.type == 'canceled',
          }"
        >
          <header class="app__order-list__order-header">
            <div
              class="app__order-list__order-rectangle"
              :style="{ height: order.status.type == 'open' ? '74px' : '54px' }"
              :class="{
                app__loading: loading,
                'app__order-list__order-rectangle--open':
                  order.status.type == 'open',
                'app__order-list__order-rectangle--closed':
                  order.status.type == 'closed',
                'app__order-list__order-rectangle--canceled':
                  order.status.type == 'canceled',
              }"
            ></div>
            <div
              class="app__order-list__order-status"
              :class="{ app__loading: loading }"
            >
              {{ order.status.generic }}
              <span
                class="app__order-list__order-date"
                v-show="!isMobile"
                :class="{
                  app__loading: loading,
                }"
              >
                {{ langs.order["order-date"] }}: {{ order.date }}
              </span>
            </div>
            <figure
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#collapse${index}`"
              aria-expanded="false"
              :aria-controls="`collapse${index}`"
              v-if="isMobile"
              class="figure-angle"
              :class="{
                'app__icon--rotate90': this.list[index].isOpen,
              }"
              :ref="setAngleRight"
              @click="toggleMenuMobile(index, order.id)"
            >
              <app-svg
                :name="icon.name"
                class="app__icon--angle-right"
              ></app-svg>
            </figure>
            <div class="row app__order-list__order-content">
              <div class="col-6">
                <span
                  class="app__order-list__order-id"
                  :class="{
                    app__loading: loading,
                    'app__order-status__id--open': order.status.type == 'open',
                  }"
                >
                  #{{ order.id }}
                </span>
                <app-tooltip
                  v-show="order.status.show"
                  class="app__order-list__order-status-store__tooltip"
                  :tooltipMessage="order.status.description"
                  customClass="delivery-tooltip"
                >
                  <span
                    class="app__order-list__order-status-store badge"
                    :id="`status-store__badge-${index}`"
                    :style="
                      styleStatusBadge(
                        order.status.background_color,
                        order.status.font_color
                      )
                    "
                  >
                    <span>{{
                      badgeText(order.status.display_name, order.status.store)
                    }}</span>
                  </span>
                </app-tooltip>
              </div>
              <div class="col-6">
                <span
                  class="app__order-list__order-total"
                  :class="{
                    app__loading: loading,
                    'app__order-status__id--open': order.status.type == 'open',
                  }"
                  v-show="!isMobile"
                >
                  {{ order.total }}
                </span>
              </div>
            </div>
          </header>
          <div :id="'collapse' + index" class="collapse" v-if="isMobile">
            <!-- :ref="setMenuMobile" -->
            <div class="app__order-list__menu-mobile">
              <div class="row app__order-list__order-details">
                <span class="col-6 app__order-list__order-date">
                  {{ langs.order["order-date"] }}: {{ order.date }}
                </span>
                <span
                  class="col-6 app__order-list__order-total"
                  :class="{
                    app__loading: loading,
                    'app__order-status__id--open': order.status.type == 'open',
                  }"
                >
                  {{ order.total }}
                </span>
              </div>
              <div class="row align-items-center">
                <div class="col-5">
                  <div v-if="hasProducts(order)">
                    <transition-group
                      v-for="(product, idx) in order.products"
                      :key="idx"
                    >
                      <div
                        :key="idx"
                        class="app__order-list__order-products"
                        :class="{ app__loading: loading }"
                      >
                        <app-order-list-products
                          :product="product"
                          :index="idx"
                          :length="order.products.length"
                        />
                      </div>
                    </transition-group>
                  </div>
                  <div v-else>
                    <div class="app__order-list__order-image app__loading" />
                  </div>
                </div>
                <div class="col-7" v-if="hasOrders">
                  <app-order-list-action
                    :order="order"
                    :class="{ app__loading: loading }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row align-items-center">
            <div class="col-5">
              <div v-if="hasProducts(order)">
                <transition-group
                  v-for="(product, idx) in order.products"
                  :key="idx"
                >
                  <div :key="idx" :class="{ app__loading: loading }">
                    <app-order-list-products
                      :product="product"
                      :index="idx"
                      :length="order.products.length"
                    />
                  </div>
                </transition-group>
              </div>
              <div v-else>
                <div class="app__order-list__order-image app__loading" />
              </div>
            </div>
            <div class="col-7" v-if="hasOrders">
              <app-order-list-action
                :order="order"
                :class="{ app__loading: loading }"
              />
            </div>
          </div>
        </article>
      </div>
    </transition-group>
    <article class="app__order-list app__component-content--wrapped" v-else>
      <app-order-not-found
        :list="storeList()"
        :loading="loading"
      ></app-order-not-found>
    </article>
    <div class="app__order-list__actions">
      <button
        data-cy="order-list__load-more"
        class="app__button app__button--primary"
        :class="{ app__loading: loadingMore || loading }"
        @click="loadingMore ? null : loadMore(), loadMorePage++"
        v-show="!isLastPage || loadingMore"
      >
        {{ this.langs.order["load-more"] }}
      </button>
    </div>
  </section>
</template>
